import * as THREE from 'three'
import { extend } from '@react-three/fiber'

export default class CircularImageMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        circularImageTexture: { type: "t", value: undefined },
        time: {type: "f", value : 0},
        isRedAlert: {type : 'b', value : false},
        uyDisplacement: { type: "f", value: 100 }
      },
      vertexShader:`
      varying vec2 vUv;
      uniform float time;
      uniform float uyDisplacement;
    
      void main() {
        vUv = uv;

        vec3 pos = position;
        // vUv.y += sin(time);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      }`,
      fragmentShader:`
      varying vec2 vUv;
      uniform sampler2D circularImageTexture;
      uniform float time;
      uniform bool isRedAlert;

      void main() {
        vec2 uv = vUv;

        //always coverts the value between 0-1 range
        float frac = (uv.y + time) - floor(uv.y + time);

        uv.y = frac;
        vec4 disp = texture2D(circularImageTexture, uv);

        if (isRedAlert) {
          disp = vec4(1.0, 0.0, 0.0, disp.a);
        }

        gl_FragColor = disp;
      }`
    });
  }

  get circularImageTexture() {
    return this.uniforms.circularImageTexture.value;
  }
  set circularImageTexture(v) {
    this.uniforms.circularImageTexture.value = v;
  }
  get yDisplacement() {
    return this.uniforms.uyDisplacement.value;
  }
  set yDisplacement(v) {
    this.uniforms.uyDisplacement = v;
  }

  get time() {
    return this.uniforms.time.value;
  }

  set time(v) {
    this.uniforms.time.value = v;
  }

  
  get isRedAlert() {
    return this.uniforms.isRedAlert.value;
  }

  set isRedAlert(v) {
    this.uniforms.isRedAlert.value = v;
  }
}


// register element in r3f (<fade />)
// extend({ StaticNoiseMaterial })
extend({ CircularImageMaterial })