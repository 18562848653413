import * as THREE from "three";
import React, { Suspense, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Html,
  Environment,
  useGLTF,
  ContactShadows,
  OrbitControls,
  Plane,
  Box,
  useTexture,
} from "@react-three/drei";

// import '../shaders/StaticNoiseMaterial'
import "../shaders/CircularImageMaterial";
import { useEffect } from "react";

export default function Screen(props) {
  const group = useRef();
  const hackingProgress = useTexture("/hacking.png");

  // const staticNoiseShader = useRef()
  const circularImageShader = useRef();

  useEffect(() => {
    circularImageShader.current.circularImageTexture = hackingProgress;
  }, []);

  let t = 0;

  //more the more solwer the movement
  let slowFactor = 2;

  useFrame((state, delta) => {
    t = ((state.clock.getElapsedTime() / slowFactor) * 100) % 100;

    if (props.alertState === 0) {
      t = Math.sin(t);
      circularImageShader.current.isRedAlert = true;
    } else {
      circularImageShader.current.isRedAlert = false;
    }

    circularImageShader.current.time = t / 100;
    circularImageShader.current.yDisplacement += t;
  });

  return (
    <group ref={group} {...props}>
      <Plane rotation-x={Math.PI / 2.06} args={[1.78, 0.86]}>
        <circularImageMaterial
          ref={circularImageShader}
          transparent
          depthWrite={false}
        />
      </Plane>
    </group>
  );
}
