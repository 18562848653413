import { useThree } from "@react-three/fiber";
import { Box } from "@react-three/drei";
import { useGLTF, useTexture, Html } from "@react-three/drei";
import { useSpring, a } from "@react-spring/three";
import { useEffect, useCallback, useState, useRef } from "react";
import Screen from "./Screen";

export default function Level() {
  const bakedTexture = useTexture("/portfolio_baked.jpg");
  const { nodes } = useGLTF("/portfolio_merged.glb");

  const { camera } = useThree();

  const [switchRotationSpring, switchRotationSpringApi] = useSpring(
    () => ({ "rotation-y": 0, config: { friction: 30 } }),
    []
  );
  const switchRotationAngles = [2 * Math.PI, Math.PI];
  const switchRotationFrictions = [
    [2, 5],
    [40, 30],
  ];
  const [switchRotationAngleIndex, setswitchRotationAngleIndex] = useState(0);

  const [showClickCue, setshowClickCue] = useState(false);
  const count = useRef(0);

  //loading entry animation
  useSpring(
    () => ({
      from: { y: camera.position.y + 3 },
      to: { y: camera.position.y },
      config: { friction: 100 },
      onChange: ({ value }) => (
        (camera.position.y = value.y), camera.lookAt(0, 0, 0)
      ),
    }),
    []
  );

  const onClick = useCallback(() => {
    switchRotationSpringApi.start({
      "rotation-y": switchRotationAngles[switchRotationAngleIndex],
    });
    count.current =
      (switchRotationAngleIndex + 1) % switchRotationAngles.length;
    setswitchRotationAngleIndex(
      (switchRotationAngleIndex + 1) % switchRotationAngles.length
    );
  });

  //floating animation
  const [floatingSpring, floatingApi] = useSpring(() => ({
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    config: { mass: 1, friction: 300 },
  }));

  //wheel roation
  const [wheelSpring1, wheelApi1] = useSpring(
    () => ({ "rotation-x": -Math.PI / 3.5, config: { friction: 40 } }),
    []
  );
  const [wheelSpring2, wheelApi2] = useSpring(
    () => ({ "rotation-x": 0, config: { friction: 35 } }),
    []
  );

  useEffect(() => {
    //float animation on entry
    let floatTimeout;
    let floating = false;
    const float = () => {
      floatingApi.start({
        position: [0, floating ? random(-0.1, 0) : random(0.2, 0.3), 0],
        //  rotation : [0,random(0,0.3),0]
      });
      floating = !floating;
      floatTimeout = setTimeout(float, random(2000, 3000));
    };
    float();

    let randomRotateTimeout1, randomRotateTimeout2, clickPreviewCue;

    const delayUntilPreview = () => {
      setshowClickCue(true);
    };

    const wander1 = (randomRotateTimeout) => {
      wheelApi1.start({
        "rotation-x": random(Math.PI * (2 / 3), (4 / 3) * Math.PI),
        config: { friction: switchRotationFrictions[count.current][0] },
      });
      randomRotateTimeout = setTimeout(wander1, 500 * random(1, 4));
    };

    const wander2 = (randomRotateTimeout) => {
      wheelApi2.start({
        "rotation-x": random(Math.PI * 0.6, (4 / 3) * Math.PI),
        config: { friction: switchRotationFrictions[count.current][1] },
      });
      randomRotateTimeout = setTimeout(wander2, 100 * random(1, 3));
    };

    onClick();
    wander1(randomRotateTimeout1);
    wander2(randomRotateTimeout2);
    clickPreviewCue = setTimeout(delayUntilPreview, 1150);

    return () => {
      clearTimeout(floatTimeout);
      clearTimeout(randomRotateTimeout1);
      clearTimeout(randomRotateTimeout2);
      clearTimeout(clickPreviewCue);
    };
  }, []);

  const random = (minRange, maxRange) => {
    const rand = Math.random() * (maxRange - minRange) + minRange;
    return rand;
  };

  return (
    <a.mesh {...floatingSpring}>
      <group position={[0, 0, 0]}>
        //visual cue added
        {showClickCue == true && (
          <Html position={[-0.9, 1.05, 1.05]}>
            <div>
              <span className="span" />
            </div>
          </Html>
        )}
        ̦
        <a.mesh
          position={[0.405, 2, 0.13]}
          rotation={[0, Math.PI + Math.PI / 2, 0]}
        >
          <Screen rotation-x={-Math.PI / 2} alertState={count.current} />
        </a.mesh>
        <a.mesh geometry={nodes.pack.geometry}>
          <meshBasicMaterial map={bakedTexture} map-flipY={false} />
        </a.mesh>
        <a.mesh geometry={nodes.screen.geometry}>
          <meshBasicMaterial map={bakedTexture} map-flipY={false} />
        </a.mesh>
        <group
          rotation={[0, 0, 1.6669971298]}
          position={[
            -0.839881,
            nodes.switch.position.y,
            -nodes.switch.position.z + 0.1,
          ]}
        >
          <Box
            args={[0.3, 0.2, 0.25]}
            onClick={() => {
              setshowClickCue(false);
              onClick();
            }}
          >
            <meshPhongMaterial color="#ff0000" opacity={0} transparent />
          </Box>
          <a.mesh geometry={nodes.switch.geometry} {...switchRotationSpring}>
            <meshBasicMaterial map={bakedTexture} map-flipY={false} />
          </a.mesh>
        </group>
        <group rotation={nodes.circular_knife.rotation}>
          <a.mesh
            geometry={nodes.circular_knife.geometry}
            position={[0.12, 1.3, 1.29]}
            {...wheelSpring1}
          >
            <meshBasicMaterial map={bakedTexture} map-flipY={false} />
          </a.mesh>
        </group>
        <group rotation={nodes.circular_knife.rotation}>
          <a.mesh
            geometry={nodes.circular_knife.geometry}
            position={[0.2, 1.3, 1.29]}
            {...wheelSpring2}
          >
            <meshBasicMaterial map={bakedTexture} map-flipY={false} />
          </a.mesh>
        </group>
      </group>
    </a.mesh>
  );
}
