import { Canvas } from "@react-three/fiber";
import { PresentationControls, Stars } from "@react-three/drei";
import Level from "./components/Level";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export default function App() {
  return (
    <>
      <Canvas
        flat
        dpr={[1, 2]}
        camera={{ fov: isMobile ? 60 : 50, position: [0, 0, 8] }}
      >
        <PresentationControls
          global
          snap={{ mass: 3, tension: 80, friction: 25 }}
          zoom={1.4}
          rotation={[Math.PI / 8, Math.PI / 1.5, 0]}
          polar={[0, Math.PI / 8]}
          azimuth={[-Math.PI / 6, Math.PI / 8]}
        >
          <group position={[0, -1.6, -0.1]} dispose={null}>
            <Level />
          </group>
        </PresentationControls>
        <Stars radius={175} depth={1} count={1000} factor={10} />
      </Canvas>
    </>
  );
}
