const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export function Underlay() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: isMobile ? 20 : 40,
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: 0,
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontFamily: "'Antonio', sans-serif",
            fontSize: isMobile ? 40 : 60,

            color: "white",
            letterSpacing: -2,
          }}
        >
          <b>Gulzar</b>
          <br />
          Creative Developer
          <br />—
        </p>
        <p
          style={{
            flex: "1 1 0%",
            height: 30,
            fontSize: 30,
            lineHeight: "30px",
            textAlign: "right",
            color: "black",
          }}
        ></p>
      </div>
    </div>
  );
}

export function Overlay() {
  return (
    <div
      style={{ position: "absolute", bottom: 40, right: isMobile ? 20 : 40 }}
    >
      <p
        style={{
          flex: "1 1 0%",
          fontSize: 12,
          lineHeight: "1em",
          textAlign: "right",
          color: "black",
        }}
      >
        <a href="https://www.linkedin.com/in/gulzar1996/">LinkedIn</a>{" "}
        <a href="https://twitter.com/gulzar_96">Twitter</a>
      </p>
    </div>
  );
}
